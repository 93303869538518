<template>
  <div class="container">
    <section>
      <v-row class="d-flex align-center">
        <v-col cols="12" md="7">
          <Card
            borderColor="#e06919"
            class="mb-5"
            title="Capela de São Bento"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                Compunha a paisagem e o núcleo urbano da comunidade a Capela de
                São Bento, que já existia em 1718. Após grande parte da
                edificação ser destruída por um incêndio, foi reconstruída em
                meados do século XIX, passando a apresentar dimensões menores
                que a construção original. Trata-se de exemplar típico das
                edificações religiosas construídas no período colonial, com
                pé-direito duplo e planta retangular. Foi implantada de modo que
                seu adro possui continuidade visual com a Praça Cônego Caetano
                Corrêa, sendo os afastamentos posterior e lateral esquerdo (sul)
                ocupados pelo cemitério. A fachada frontal era composta pelo vão
                centralizado da porta principal e por duas janelas simétricas no
                nível do coro com parapeito sacado, vedados por folhas de
                madeira almofadadas. Todos os vãos apresentavam vergas em arco
                abatido e enquadramento em madeira. A empena da fachada frontal
                era encimada por uma cruz latina – símbolo do catolicismo – e
                adornada por um óculo centralizado e também por um escudo
                talhado em madeira sobre a porta. Na fachada lateral direita
                (norte), na altura do coro, havia um vão onde ficava o sino,
                emoldurado por marco de madeira.
              </p>
              <p>
                O sistema construtivo compunha-se por alicerce de pedras,
                estrutura autônoma de madeira com vedação em pau a pique
                revestida por reboco com pintura na cor branca. O telhado era
                disposto em duas águas, com cumeeira perpendicular à fachada
                frontal, engradamento de madeira coberto por telhas cerâmicas
                curvas, beiral arrematado por guarda-pó e cachorros em madeira.
                A capela herdada pela comunidade do período colonial foi
                destruída pela lama de rejeitos; o alicerce de pedras, o piso de
                ardósia do nártex (área de entrada do templo), o piso tabuado em
                campas e o tabuado da capela-mor permaneceram no local após a
                devastação do território.
              </p>
              <p style="text-align: center">
                <strong>Autora: Ana Paula Alves Ferreira<sup>1</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="70%"
            src="../../assets/sobre_cultura_bento_capela_01.png"
          ></v-img>
          <p style="font-size: 0.6em; width:70%; text-align: center;">
            Vista da Capela de São Bento a partir do adro, anteriormente ao
            rompimento da barragem. Foto: Acervo do COMPAT Mariana / Jornal A
            Sirene, 2021.
          </p>
          <v-img
            max-width="70%"
            src="../../assets/sobre_cultura_bento_capela_02.png"
          ></v-img>
          <p style="font-size: 0.6em; width:70%; text-align: center;">
            Vista da Capela de São Bento e da antiga hospedagem à esquerda da
            imagem. Autor e data não identificados.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p class="mb-0">
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <strong>Referências Bibliográficas</strong>
            </p>
            <p>
              CASTRIOTA, Leonardo Barci. Dossiê de Tombamento de Bento
              Rodrigues. UFMG. Belo Horizonte, 2019. Disponível em:
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://issuu.com/patrimonioculturalmpmg/docs/bentorodriguesdossietombamento"
                class="pink--text"
                >https://issuu.com/patrimonioculturalmpmg/docs/bentorodriguesdossietombamento</a
              >. Acesso em: 30 mar. 2022.
            </p>
            <p>
              PÓLEN Consultoria Patrimônio e Projetos Ltda. Diagnóstico
              preliminar dos bens culturais identificados no território atingido
              em Mariana pelo rompimento da barragem de Fundão. Mariana, 2017.
            </p>
            <p>
              TEIXEIRA, Vanessa Cerqueira. Fé e cultura barroca sob o manto
              mercedário: devoção, religiosidade e vivências a partir da
              irmandade de Nossa Senhora das Mercês de Mariana (1749-1822). 220
              f. 2017. Dissertação (Mestrado em História) — Instituto de
              Ciências Humanas, Universidade Federal de Juiz de Fora Juiz de
              Fora-MG, 2017.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
section {
  margin-bottom: 3em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
